import { useState } from "react";
import { Card, CardContent, CardActions, Button, Typography, Box } from "@mui/material";
import MarkdownContainer from "../Markdown/MarkdownContainer";

const SampleOutput = ({ sampleOutput, submitCreateRule, loading, acceptDisable }) => {
    

    const handleAccept = () => {
        submitCreateRule();

    };

    return (
        <Card className="flex-1 p-4" style={{ position: "relative" }}>
            {loading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1,
                        overflow: "hidden"
                    }}
                >
                    <Box
                        sx={{
                            width: "150%",
                            height: "5px",
                            background: "linear-gradient(45deg, #ff4081, #3f51b5)",
                            animation: "diagonal-loader 1s infinite"
                        }}
                    />
                    <Typography
                        variant="h6"
                        sx={{ mt: 2 }}
                        color="secondary"
                    >
                        Processing, please wait...
                    </Typography>
                    <style>
                        {`
                        @keyframes diagonal-loader {
                            0% { transform: translateX(-100%); }
                            100% { transform: translateX(100%); }
                        }
                        `}
                    </style>
                </Box>
            )}

            {/* <CardContent>
                <Typography variant="h6" gutterBottom>
                    Sample Output
                </Typography>
                <MarkdownContainer content={sampleOutput} />
            </CardContent> */}

            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Sample Output
                </Typography>
                <MarkdownContainer content={sampleOutput} />
            </CardContent>

            <CardActions className="justify-end">
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleAccept}
                    disabled={acceptDisable}
                >
                    Accept
                </Button>
            </CardActions>
        </Card>
    );
};

export default SampleOutput;
