const blinkingCursorStyle = {
    display: 'inline-block',
    animation: 'blink 1s step-end infinite',
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: 'currentColor',
  };
  
  const keyframes = `
    @keyframes blink {
      0% { opacity: 1; }
      50% { opacity: 0; }
      100% { opacity: 1; }
    }
  `;
  
  export default function BlinkingCursor() {
    return (
      <>
        <style>{keyframes}</style>
        <span style={blinkingCursorStyle}>|</span>
      </>
    );
  }