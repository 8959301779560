  import * as React from "react";
  import Box from '@mui/material/Box';
  import IconButton from '@mui/material/IconButton';
  import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
  import SettingsMenu from "../utils/SettingsMenu";
  import ProfileAvatar from "../utils/ProfileAvatar";
  import { styled } from "@mui/material/styles";
  import { Typography, useMediaQuery } from "@mui/material";
  import { useLocation } from "react-router-dom";
  import MenuIcon from '@mui/icons-material/Menu';
import { useFeedbackQContext } from "../../context/FeedbackQ";

  function Header({setToken, token, toggleSidebar}) {
    const DrawerFooter = styled("div")(({ theme }) => ({
      display: "flex",
      width: "100%",
      backgroundColor: '#37517e',
      justifyContent: "space-between",
      alignItems: "center",
      height: "72px",
      position: "fixed",
      zIndex: 100,
      padding: "0 16px",
      [theme.breakpoints.down('md')]: {
        height: '64px',
      }
    })); 
    const location = useLocation();
    const rules = location.pathname.split("/").pop();
    const showHamburger = useMediaQuery('(max-width:768px)');
    const { name } = useFeedbackQContext();

    return (
      <DrawerFooter>
        {showHamburger && (
          <IconButton
              edge='start'
              color="inherit"
              aria-label="menu"
              sx={{ color: 'whitesmoke' }}
              onClick={toggleSidebar}
          >
              <MenuIcon />
          </IconButton>
        )}
        <Box
          sx={{
            display: 'flex',
            flex: 1, 
            alignItems: 'center',
            marginLeft: showHamburger ? 0 : "50px",
            overflow: 'hidden'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              flex: 1,
              color: '#fff',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              lineHeight: 1.5,
              fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },  
              padding: { xs: '0 16px', md: '0' },
              textAlign: { xs: 'center', md: 'left' },        
            }}
            gutterBottom
          >
            {rules === "rules" ? "Rules" : ""}
            {rules === "reviews" ? "Feedback Details" : ""}
            {rules === "ask-q" ? "AskQ" : ""}
            {rules === "themes" ? "Themes" : ""}
            {rules === "source" ? "Create or Collect Feedback" : ""}
            {rules === "book-call" ? "Book Your Call" : ""}
          </Typography>
          <Box sx={{ display: "flex", alignItems: 'center', gap: 2, paddingRight: { xs: 1, sm: 2, md: 4, lg: 33 }}}>
            <IconButton sx={{ color: "white" }}>  
              <HelpOutlineIcon/>
            </IconButton>
            <SettingsMenu/>
            <ProfileAvatar name={name} setToken={setToken} token={token} />
          </Box>
        </Box>     
      </DrawerFooter>
    );
  }

  export default Header;
