import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import defaultRuleIcon from '../../assets/icons/default-icon.png';
const CustomRule = ({ rule, onEdit, onRemove, onSaveToggle, onOpenDrawer, onEditTemplate , status}) => {
  const handleClick = () => {
    if (!rule.save && rule.template) {
      onOpenDrawer(rule.defaultID, false); 
    }
  };

  return (
    <Box
      className={`relative border border-gray-300 rounded-lg shadow-md p-6 mb-4 hover:bg-gray-50 group 
        ${!rule.save && rule.template ? 'cursor-pointer' : ''}`
      }
      onClick={handleClick}
      style={status ? { pointerEvents: 'none', opacity: 0.6 } : {}}
    >
      <Box className="flex justify-between items-start">
        <Typography variant="body1" className='font-semibold'>{rule.title}</Typography>
        <Box className="flex space-x-2">
          {rule.save && (
            <Tooltip title="Saved">
              <BookmarkIcon color="primary" />
            </Tooltip>
          )}
          {rule.defaultID && (
            <IconButton>
              <Tooltip title="Default Template">
                <img src={defaultRuleIcon} alt="default rule" className="w-5 h-5" />
              </Tooltip>
            </IconButton>
          )}
        </Box>
      </Box>

      <Box className="absolute top-2 right-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        {rule.save && rule.defaultID ? (
          // Show only the Edit icon when the rule is saved and is a default template
          <Tooltip title="Edit">
            <IconButton onClick={(e) => { e.stopPropagation(); onEditTemplate(rule.defaultID, true); }}>
              <EditIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        ) : (
          !rule.template && (
            <>
              <Tooltip title="Edit">
                <IconButton onClick={(e) => { e.stopPropagation(); onEdit(rule); }}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove">
                <IconButton onClick={(e) => { e.stopPropagation(); onRemove(rule); }}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </>
          )
        )}
      </Box>
      {status && (
        <Box className="absolute inset-0 bg-gray-100 bg-opacity-75 flex items-center justify-center">
          <Typography variant="h6" color="textSecondary">
            Processing...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomRule;