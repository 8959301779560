import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useFeedbackQContext } from '../../context/FeedbackQ';

const SearchBox = ({ query, setQuery, onSearch }) => {
  const { isTyping } = useFeedbackQContext();
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isTyping) {
      e.preventDefault();
      onSearch();
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="mb-4 overflow-y-auto" style={{ maxHeight: '200px'}}>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Search…"
        value={query}
        multiline
        minRows={1}
        InputProps={{
          endAdornment: (
            <InputAdornment 
              position="end"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '100%'
              }}
            >
              <IconButton
                edge="end"
                aria-label="search"
                onClick={onSearch}
                sx={{ alignSelf: 'flex-end'}}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default SearchBox;


