import { Box, Paper, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

const months1 = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];
// const csv = [65, 59, 80, 81, 56, 55, 40];
// const api = [28, 48, 40, 19, 86, 27, 90];
// const playsotre = [10, 20, 0, 100, 30, 20, 10];
// const appstore = [100, 0, 50, 0, 0, 10, 0];
// const g2 = [0, 0, 70, 0, 10, 110, 50];

export default function FullWidthMUILineChart({ months = [], monthlyNPSData= {}}) {
  const {
    play_store = [],
    app_store = [],
    csv = [],
    api = [],
    g2 = []
  } = monthlyNPSData;
  return (
    <Box className="w-full max-w-8xl mx-auto p-4">
      <Paper elevation={3} className="p-4">
        <Typography variant="h5" component="h2" gutterBottom className="text-center">
          APP Performance Chart
        </Typography>
        <Box className="w-full h-[400px]">
          <LineChart
            xAxis={[{ data: months, scaleType: 'band' }]}
            series={[
              {
                data: csv,
                label: 'CSV',
                color: 'rgba(255, 99, 132, 1)',
              },
              {
                data: api,
                label: 'API',
                color: 'rgba(53, 162, 235, 1)',
              },
              {
                data: play_store,
                label: 'Play Store',
                color: 'rgba(255, 206, 86, 1)',
              },
              {
                data: app_store,
                label: 'App Store',
                color: 'rgba(75, 192, 192, 1)',
              },
              {
                data: g2,
                label: 'G2',
                color: 'rgba(100, 139, 2, 1)',
              }
            ]}
          />
        </Box>
      </Paper>
    </Box>
  );
}