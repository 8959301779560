import React from 'react';
import { Box, Typography } from '@mui/material';
import ResponseContainer from './ResponseContainer';

const SearchContainer = ({ searchTerms, responses, loading }) => {
    return (
        <Box>
            {searchTerms.map((term, index) => (
                <Box key={index} mb={2}>
                {index >= 0 && (
                    <Typography variant="body1">Search Term: <span className='text-gray-800 font-bold'>{term}</span></Typography>
                )}
                <ResponseContainer 
                    responses={responses[index] ? [responses[index]] : []} 
                    isNew={index=== searchTerms.length - 1? true : false}
                    resultIndex={index}
                />
                </Box>
            ))}
        </Box>
    );
};

export default SearchContainer;