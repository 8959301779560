import React from 'react';
import { Card, CardContent, Typography, Box } from "@mui/material";
import ProgressBar from './ProgressBar';

const ProgressBlock = ({ detractors, passives, promoters }) => {
 


  return (
    <Card sx={{ borderRadius: 2, padding: 2, height: 371 }}>
      <CardContent>
        <Typography variant="h6" className="font-bold text-gray-700 text-center">
          Feedback Distribution
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" className="font-bold text-gray-700">
            Detractors
          </Typography>
          <ProgressBar value={detractors?.percentage} />
          <Typography variant="body2" className="text-center mt-1">
            {`+${detractors?.count?.toLocaleString()}`}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" className="font-bold text-gray-700">
            Passives
          </Typography>
          <ProgressBar value={passives?.percentage} />
          <Typography variant="body2" className="text-center mt-1">
            {`+${passives?.count?.toLocaleString()}`}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" className="font-bold text-gray-700">
            Promoters
          </Typography>
          <ProgressBar value={promoters?.percentage} />
          <Typography variant="body2" className="text-center mt-1">
            {`+${promoters?.count?.toLocaleString()}`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProgressBlock;
