import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import SearchBox from './askQ/SearchBox';
import PreBuiltQueries from './askQ/PreBuiltQueries';
import SearchContainer from './askQ/SearchContainer';
import FollowUpSearchBox from './askQ/FollowUpSearchBox';
import { CircleContainer } from '../muitheme/StyledComponents';
import { useFeedbackQContext } from '../context/FeedbackQ';

const AskQ = () => {
    const [searchTerms, setSearchTerms] = useState([]);
    const [showResponse, setShowResponse] = useState(false);
    const [expandableSearch, setExpandableSearch] = useState(false);
    const [followUpQuery, setFollowUpQuery] = useState(''); 
    const [chatID, setChatID] = useState(null);
    const [messageID, setMessageID] = useState(null);
    const [parentMessageID, setParentMessageID] = useState(null);
    const [loading, setLoading] = useState(false);
    const {searchResult, setSearchResult } = useFeedbackQContext();

    const handleQuery = async (term, searchType = 'main') => {
        try {
            // Check if chatID is already set
            setLoading(true)
            if (!chatID) {
                let dashboard_id = localStorage.getItem('dashboardId');

                if (dashboard_id) {
                    dashboard_id = dashboard_id.trim();
                    try {
                        // Create chat session
                        const chatSessionResponse = await fetch(`https://feedbackq-service.insightq.ai/askq/chat_session/${dashboard_id}`)
                        // const chatSessionResponse = await fetch(`https://feedbackq-service.insightq.ai/askq/chat_session/66e85b4aeb3ad24698e73d0b`)
                        if (!chatSessionResponse.ok) {
                            throw new Error(`HTTP error! Status: ${chatSessionResponse.status}`);
                        }

                        const chatSessionData = await chatSessionResponse.json();
                        setChatID(chatSessionData.chat_session_id);

                        // Use the newly created chat session ID for the query
                        await submitQuery(chatSessionData.chat_session_id, term, searchType);
                    } catch (error) {
                        console.error('Error creating chat session:', error.message);
                    }
                } else {
                    console.warn('Dashboard ID is not available in localStorage.');
                }
            } else {
                // If chatID is already available, proceed with the query
                await submitQuery(chatID, term, searchType);
            }
        } catch (error) {
            console.error("Error during query handling:", error);
        } finally {
            setLoading(false);
        }
    };

    const submitQuery = async (chat_session_id, term, searchType) => {
        try {
            const response = await fetch(`https://feedbackq-service.insightq.ai/askq/search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_session_id: chat_session_id,
                    parent_message_id: parentMessageID,
                    message: term
                })
            });
            const data = await response.json();
            const resp = data.message;
            if (resp) {
                setShowResponse(true);
                const botResponse = resp;
    
                // Introduce a small delay before starting the streaming response
                setTimeout(() => {
                    setSearchResult((prevMessages) => [
                        ...prevMessages,
                        { role: 'assistant', content: botResponse }  // Start with an empty message for streaming
                    ]);
                    // simulateStreamingResponse(botResponse);
                }, 1000);  // Adjust the timeout as needed
            }
    
            if (searchType === 'main') {
                setSearchTerms((prevTerms) => [...prevTerms, term]);
            }
            setExpandableSearch(true);
            setFollowUpQuery('');
            setParentMessageID(data.parent_message);
            setMessageID(data.message_id);
    
        } catch (error) {
            console.error("Error during search query:", error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleSearchInputChange = (event) => {
        const newTerm = event.target.value;
        setSearchTerms((prevTerms) => {
            const updatedTerms = [...prevTerms];
            if (updatedTerms.length > 0) {
                updatedTerms[updatedTerms.length - 1] = newTerm;
            } else {
                updatedTerms.push(newTerm);
            }
            return updatedTerms;
        });
    };

    const handleSearchSubmit = async () => {
        if (searchTerms.length > 0 && searchTerms[searchTerms.length - 1].trim() !== '') {
            await handleQuery(searchTerms[searchTerms.length - 1], 'folloup');
        } else {
            console.warn('Invalid search term or no term provided.');
        }
    };

    const handleFollowUpSearch = () => {
        if (followUpQuery.trim() !== '') {
            handleQuery(followUpQuery);
        }
    };
    

    return (
        <div className='flex flex-col min-h-[92vh] bg-gray-50 justify-start items-center pt-[12px]'>    
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                    width: '100%',
                    backgroundColor: '#f8f9fa',
                    marginBottom: '24px',
                }}
            >
                <Box className="container mx-auto p-8 bg-white shadow-xl rounded-lg w-11/12 sm:w-9/12 md:w-7/12 lg:w-6/12 xl:w-5/12">
                    <Box className="flex flex-col">
                        {!showResponse && (
                            <SearchBox 
                                query={searchTerms[0] || ''}
                                setQuery={(term) => {
                                    handleSearchInputChange({ target: { value: term } });
                                }}
                                onSearch={handleSearchSubmit} 
                            />
                        )}
                        {!showResponse && loading && (
                            <Box className="flex justify-center items-center h-32">
                                <CircleContainer >
                                    <CircularProgress />
                                </CircleContainer>
                            </Box>
                        )}
                        {showResponse && (
                            <>
                                <SearchContainer 
                                    searchTerms={searchTerms} 
                                    responses={searchResult} 
                                    loading={loading} 
                                />
                                {expandableSearch && ( 
                                    <>
                                        { loading ? (
                                            <Box className="flex justify-center items-center h-32">
                                                <CircleContainer >
                                                    <CircularProgress />
                                                </CircleContainer>
                                            </Box>
                                        ) : (
                                            <FollowUpSearchBox 
                                                query={followUpQuery}
                                                setQuery={setFollowUpQuery}
                                                onSearch={handleFollowUpSearch}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {searchResult.length === 0 && (
                            <PreBuiltQueries onClickQuery={handleQuery} />
                        )}
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default AskQ;