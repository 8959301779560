import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import DOMPurify from 'dompurify'; // Use this to sanitize HTML
import { formatAskQResponse } from '../../helper/helper';
import StopIcon from '@mui/icons-material/Stop';
import { useFeedbackQContext } from '../../context/FeedbackQ';
import BlinkingCursor from '../utils/BlinkingCursor';

function renderMarkdown(markdownText) {
  // Convert markdown syntax to HTML
  const htmlContent = markdownText
    .replace(/#/g, '<h1>') // Headers
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
    .replace(/_(.*?)_/g, '<em>$1</em>') // Italics
    .replace(/- (.*?)(\n|$)/g, '<li>$1</li>') // Lists
    .replace(/\n\n+/g, '<br><br>') // Multiple new lines to paragraphs
    .replace(/\n/g, '<br>'); // Single new lines to line breaks

  // Sanitize the HTML to prevent XSS attacks
  return DOMPurify.sanitize(htmlContent);
}

// Custom typing effect hook to display markdown content gradually
const useTypingEffect = (text, speed = 50, stopped) => {
  const [displayText, setDisplayText] = useState('');
  const [typingComplete, setTypingComplete] = useState(false);
  

  useEffect(() => {
    if (stopped) { 
      setTypingComplete(true);
      return; // Exit early if typing was stopped
    }

    let index = 0;
    let frameId;
    let timeoutId;

    const type = () => {
      setDisplayText(text.slice(0, index + 1));
      index += 1;

      if (index < text.length) {
        timeoutId = setTimeout(() => {
          frameId = requestAnimationFrame(type);
        }, speed);
      } else {
        setTypingComplete(true); // Mark typing as complete
      }
    };

    setTypingComplete(false); // Reset the typing complete flag

    timeoutId = setTimeout(() => {
      frameId = requestAnimationFrame(type);
    }, speed);

    // Cleanup on unmount or when text changes
    return () => {
      cancelAnimationFrame(frameId);
      clearTimeout(timeoutId);
    };
  }, [text, speed, stopped]);

  return { displayText, typingComplete };
};

const ResponseContainer = ({ responses = [], isNew, resultIndex }) => {
  const [plainText, setPlainText] = useState('');
  const [stopped, setStopped] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setIsTyping, setSearchResult, isTyping } = useFeedbackQContext();

  useEffect(() => {
    const responseText = formatAskQResponse(responses);
    const plainResponse = renderMarkdown(responseText);
    setPlainText(plainResponse);
  }, [responses]);

  const handleStop = () => {
    setStopped(true); // Stop the typing effect and mark as complete
    setLoading(false);

    setSearchResult((prevMessages) => {
      const updatedResults = [...prevMessages]; // Create a copy of the current results
      updatedResults[resultIndex] = { role: 'assistant', content: displayText }; // Update the entry at resultIndex
      return updatedResults; // Return the updated array
    });
  };

  // Use the typing effect only for the last search term, else render plain text
  const { displayText, typingComplete } = useTypingEffect(plainText, 10, stopped);

  useEffect(() => {
    if (isNew) {
      setIsTyping(!typingComplete);
    }
  }, [typingComplete, isNew, setIsTyping]);

  return (
    <Box className="mt-4 p-4 bg-white shadow-md rounded-lg">
      <Typography variant="h6" gutterBottom>
        Q says
      </Typography>

      <Box className="shadow-sm">
        {loading ? (
          <Box className="flex justify-center items-center h-32">
            <CircularProgress />
          </Box>
        ) : (
          <Typography
            component="div"
          >
            <span dangerouslySetInnerHTML={{ __html: isNew ? displayText : plainText }} />
            {isTyping && isNew && <BlinkingCursor />} 
          </Typography>

        )}
      </Box>

      {isNew && !stopped && !typingComplete && displayText.length > 15 && (
        <Box className="mt-4 flex justify-end">
          <Button
            variant="contained" 
            color="primary"
            startIcon={<StopIcon />}
            onClick={handleStop}
            className="bg-red-500 hover:bg-red-600 text-white"
          >
            Stop
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ResponseContainer;
