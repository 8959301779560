import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { Box } from '@mui/material';
import insightQIcon from '../../assets/icons/favicon.png';
import { useFeedbackQContext } from '../../context/FeedbackQ';

const FollowUpSearchBox = ({ query, setQuery, onSearch }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isTyping } = useFeedbackQContext();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isTyping) {
      e.preventDefault(); // Prevent default action to avoid form submission
      onSearch();
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  // Set width to 520px if hovered or if there is a value, otherwise only show the icon
  const searchBoxWidth = isHovered || query.length > 0 ? '520px' : '46px';

  return (
    <Box
      className="relative flex items-center transition-all duration-300"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TextField
        variant="outlined"
        placeholder={isHovered || query.length > 0 ? 'Search…' : ''}
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                src={insightQIcon}
                alt="search"
                className={`w-5 h-5 transition-all duration-300 `}
                style={{marginRight: 3}}
              />
            </InputAdornment>
          ),
          sx: {
            width: searchBoxWidth,
            height: '40px',
            background: 'white',
            borderRadius: '40px',
            transition: 'width 0.5s ease',
            '& .MuiOutlinedInput-root': {
              padding: '0 10px',
              '& fieldset': {
                border: 'none',
              },
            },
            '& .MuiInputBase-input': {
              padding: isHovered || query.length > 0 ? '0 10px' : '0',
              width: isHovered || query.length > 0 ? '100%' : '0',
              transition: 'width 0.5s ease, padding 0.5s ease',
              lineHeight: '40px',
            },
          },
        }}
      />
    </Box>
  );
};

export default FollowUpSearchBox;
