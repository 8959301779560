"use client"

import { 
  Button, 
  Container, 
  Typography, 
  Box
} from '@mui/material'
import { Calendar as CalendarIcon } from 'lucide-react'

export default function BookYourCall() {

  const handleSubmit = (event) => {
    event.preventDefault()
    // redirecting
    window.open('https://calendly.com/bishwarup-insightq/30min', '_blank', 'noopener,noreferrer');
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          py: 4,
        }}
      >
        <CalendarIcon size={64} color="#1976d2" />
        <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4, mb: 2 }}>
          Book Your Call
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
          Click below to schedule your demo call with the Founder
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%', maxWidth: 400 }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            startIcon={<CalendarIcon />}
          >
            Schedule Demo
          </Button>
        </Box>
      </Box>
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Thank you! We'll use your Calendly link to schedule the call.
        </Alert>
      </Snackbar> */}
    </Container>
  )
}