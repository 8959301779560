import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Icon,
  Avatar,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import addIcon from "../../assets/icons/add.png";
import FormPopup from "../Dashboard/FormPopup";
import {
  createDashboard,
  fetchDashboards,
  getDashboardIdByName,
} from "../../api/API"; // Import the API functions
import { useFeedbackQContext } from "../../context/FeedbackQ";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../context/Notification";
import { getColorForCharacter } from "../../helper/characterColor";

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}.slice(-2)`;
  }

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      color: "#fff",
      width: 24,
      height: 24,
      fontSize: 12,
    },
    children: `${name[0]}`,
  };
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    margin: "10px 0px 0px -210px",
    borderRadius: 10,
    minWidth: 230,
    boxShadow: "0px 4px 8px rgba(0,0,0,0.2), 0px -4px 8px rgba(0,0,0,0.2)",
    "& .MuiMenuItem-root": {
      fontSize: 16,
      fontWeight: 500,
      padding: theme.spacing(1.5, 2),
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
      "&:hover": {
        backgroundColor: theme.palette.primary.gray,
        color: theme.palette.primary.darkgray,
      },
    },
  },
}));

const PlayGroundDropDown = ({ anchorEl, handleClose, setName }) => {
  const [openForm, setOpenForm] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [newWorkspace, setNewWorkspace] = useState({
    workspaceName: "",
    description: "",
  });
  const { dashboardCheck, setDashboardCheck } = useFeedbackQContext()
  const { showNotification  } = useNotification();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInitialDashboards = async () => {
      try {
        // Fetch all dashboards including the default one
        const orgId = sessionStorage.getItem('orgId')
        const dashboards = await fetchDashboards(orgId);
        // console.log(dashboards)
        setDashboards(dashboards);
        // Check if a dashboard ID is stored in localStorage
        const storedDashboardId = localStorage.getItem("dashboardId");
        
        if (storedDashboardId) {
          // Find the stored dashboard in the fetched dashboards
          const dashboardId = dashboards.find(
            (dashboard) => dashboard._id === storedDashboardId
          );
          if (dashboardId) {
            setName(dashboardId.dashboard_name);
          } else {
            // If the stored dashboard is not found, fallback to "Main Dashboard"
            setDefaultDashboard(dashboards);
          }
        } else {
          // No dashboard ID stored, set "Main Dashboard" as default
          setDefaultDashboard(dashboards);
        }
      } catch (error) {
        console.error("Error fetching dashboards:", error);
      }
    };

    fetchInitialDashboards();
  }, []);

  const setDefaultDashboard = (dashboards) => {
    // Find "Main Dashboard" from the list
    const mainDashboard = dashboards.find(
      (dashboard) => dashboard.dashboard_name === "Default Dashboard"
    );
    if (mainDashboard) {
      setName(mainDashboard.dashboard_name);
      localStorage.setItem("dashboardId", mainDashboard._id); // Store Main Dashboard ID in localStorage
    }
  };

  const handleFormOpen = () => {
    setOpenForm(true);
  };

  const handleFormClose = () => {
    setOpenForm(false);
    setNewWorkspace({
      workspaceName: "",
      description: "",
    });
    handleClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWorkspace((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateWorkspace = async () => {
    try {
      // console.log("Creating workspace with data:", newWorkspace);
      const orgId = sessionStorage.getItem('orgId')
      const response = await createDashboard(
        newWorkspace.workspaceName,
        newWorkspace.description,
        orgId
      );
      // console.log("Workspace created successfully:", response);
      const msg = response.msg
      if (msg === 'dashboards_exhausted'){
        showNotification('error', 'dashboards limit reached. Unable to create more dashboard.')
      }
      else{
        // Refresh dashboards after creating a new one
        const dashboards = await fetchDashboards(orgId);
        // console.log(dashboards)
        setDashboards(dashboards);
        handleFormClose();
        handleDashboardClick(newWorkspace.workspaceName, true)
        showNotification('success', 'Dashboard created successfully')
      }
      
    } catch (error) {
      console.error("Error creating workspace:", error);
    }
  };

  const isFormValid = () => {
    return Object.values(newWorkspace).every((value) => value.trim() !== "");
  };

  const handleDashboardClick = async (dashboardName, isNew=false) => {
    try {
      // Call API to get the _id of the clicked dashboard
      const orgId = sessionStorage.getItem('orgId')
      const dashboardId = await getDashboardIdByName(dashboardName, orgId);
      // console.log("Dashboard ID:", dashboardId, typeof(dashboardId));
      
      // Store the dashboard ID in localStorage for later use
      localStorage.setItem("dashboardId", dashboardId);
      setDashboardCheck(!dashboardCheck);
      // Set the selected dashboard name
      setName(dashboardName);

      handleClose();

      if(isNew) navigate('/')
    } catch (error) {
      console.error("Error fetching dashboard ID:", error);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledMenu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dashboards.map((dashboard) => (
          <MenuItem
            key={dashboard._id}
            onClick={() => handleDashboardClick(dashboard.dashboard_name)}
          >
            <Avatar {...stringAvatar(dashboard.dashboard_name)} sx={{ bgcolor:getColorForCharacter(dashboard?.dashboard_name.charAt(0))}} />
            <ListItemText
              primary={
                <span
                  style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    display: 'block',
                    maxWidth: '160px', // Adjust this to control the breaking point
                  }}
                >
                  {dashboard.dashboard_name}
                </span>
              }
            />
          </MenuItem>
        ))}
        <MenuItem onClick={handleFormOpen}>
          <ListItemIcon>
            <Icon>
              <img src={addIcon} alt="Add" style={{ width: 24, height: 24 }} />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary="Create new workspace"
            sx={{ marginLeft: "-11px" }}
          />
        </MenuItem>
      </StyledMenu>
      {openForm && (
        <div style={{ position: "absolute", top: 0, left: "100%", zIndex: 1 }}>
          <FormPopup
            openForm={openForm}
            handleFormClose={handleFormClose}
            newWorkspace={newWorkspace}
            handleInputChange={handleInputChange}
            handleCreateWorkspace={handleCreateWorkspace}
            isFormValid={isFormValid}
          />
        </div>
      )}
    </div>
  );
};

export default PlayGroundDropDown;
