import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons';
import HomeIcon from '@mui/icons-material/Home';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import GradingIcon from '@mui/icons-material/Grading';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import APIIcon from '@mui/icons-material/ApiSharp';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Icons = {
    themes: <GradingIcon/>,
    snippet: <TextSnippetIcon/>,
    homeIcon: <HomeIcon />,
    inboxIcon: <InboxIcon/>,
    csv: <CloudUploadIcon style={{ marginRight: '10px', marginTop: '5px' }} />,
    googlePlay: <FontAwesomeIcon icon={faGooglePlay} style={{ marginRight: '10px', marginTop: '5px' }} />,
    appStore: <FontAwesomeIcon icon={faAppStore} style={{ marginRight: '10px', marginTop: '5px' }} />,
    g2: <img src="https://cdn.iconscout.com/icon/premium/png-512-thumb/g-2-file-4016188-3340076.png?f=webp&w=256" alt="" style={{ width: "30px", height: "25px", objectFit: "contain", marginRight: '5px', marginTop: '2px' }} />,
    rules: <CodeOffIcon />,
    askQ: <ChatIcon />,
    accInfo: <AccountCircleIcon />,
    api: <APIIcon />,
    bookcall: <CalendarTodayIcon />,
};

export default Icons;