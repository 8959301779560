import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { useFeedbackQContext } from '../context/FeedbackQ';
import Dashboard from './Dashboard';
import { fetchDashboardData, fetchDataSources } from '../api/API';
import SkeletonDashboard from './utils/shimmerUI/SkeletonDashboard';

const TitleSection = () => {
    const { hasApps, setHasApps, dashboardCheck, analysisCheck } = useFeedbackQContext();
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({})
    const [categoryData, setCategoryData] = React.useState({});
    const [sourceData, setSourceData] = React.useState({});
    const [csat, setCsat] = React.useState(0);
    const [nps, setNps] = React.useState(0);
    const [npsDistribution, setNpsDistribution] = React.useState({
        Promoters: { count: 0, percentage: 0 },
        Detractors: { count: 0, percentage: 0 },
        Passives: { count: 0, percentage: 0 }
    });
    const [npsData, setNpsData] = React.useState([['Month', 'NPS Score', 'No. of Responses'], ['month', 0, 0]]);
    const [monthlyNPSData, setMonthlyNPSData] = React.useState({
        csv: Array(12).fill(0),         // Initialize with 0 for each month
        api: Array(12).fill(0),
        play_store: Array(12).fill(0),
        app_store: Array(12).fill(0),
        g2: Array(12).fill(0),
    });
    const [months, setMonths] = React.useState([
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]);
    const [promotersWordCloud, setPromotersWordCloud] = React.useState([
        { text: 'initialWord1', value: 50 },
        { text: 'initialWord2', value: 30 },
        { text: 'initialWord3', value: 75 },
        { text: 'initialWord4', value: 45 },
        { text: 'initialWord5', value: 90 }
    ]);
    const [detractorsWordCloud, setDetractorsWordCloud] = React.useState([
        { text: 'initialWord1', value: 50 },
        { text: 'initialWord2', value: 30 },
        { text: 'initialWord3', value: 75 },
        { text: 'initialWord4', value: 45 },
        { text: 'initialWord5', value: 90 }
    ]);
    const [empathyState, setEmpathyState] = React.useState({
        Says: {
            title: 'SAYS',
            question: 'What do you hear them saying? What do they talk about?',
            data: []
        },
        Thinks: {
            title: 'THINKS',
            question: 'What are they worried/excited about? What are they thinking about?',
            data: []
        },
        Does: {
            title: 'DOES',
            question: 'What are their daily activities (at home or at work)?',
            data: []
        },
        Feels: {
            title: 'FEELS',
            question: 'How do they feel on a daily basis?',
            data: []
        },
        Pain: {
            title: 'PAIN',
            question: 'What are their frustrations and challenges?',
            data: []
        },
        Gain: {
            title: 'GAIN',
            question: 'What are their goals? What makes them happy?',
            data: []
        }
    });

    React.useEffect(() => {
        const checkApps = async () => {
            setLoading(true);
            const dashboard_id = localStorage.getItem("dashboardId");
            const orgId = sessionStorage.getItem("orgId")

            try {
                const fetchData = await fetchDataSources(dashboard_id);
                if (fetchData.datasources.length > 0) {
                    const response = await fetchDashboardData(orgId, dashboard_id)
                    console.log("dashboard response :: ", response)
                    setData(response)
                    setHasApps(true);
                } else {
                    setHasApps(false);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error occured while getting data source ', error);
            } 
        }
        checkApps()
    }, [dashboardCheck, analysisCheck]);

    React.useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            const isDataComplete = (
                data?.csat !== undefined &&
                data?.nps !== undefined &&
                data?.nps_distribution !== undefined &&
                data?.source_count_data !== undefined &&
                data?.category_count_data !== undefined &&
                data?.nps_by_source !== undefined &&
                data?.promoters_word_cloud !== undefined &&
                data?.detractor_word_cloud !== undefined &&
                data?.empathies !== undefined
            );

            if (isDataComplete) {
                setCategoryData({
                    newFeatureRequest: {
                        count: data?.category_count_data?.['New Feature Request']?.count,
                        percentage: data?.category_count_data?.['New Feature Request']?.percentage,
                    },
                    bugReport: {
                        count: data?.category_count_data?.['Bug Report']?.count,
                        percentage: data?.category_count_data?.['Bug Report']?.percentage,
                    },
                    appreciation: {
                        count: data?.category_count_data?.Appreciation?.count,
                        percentage: data?.category_count_data?.Appreciation?.percentage,
                    },
                    question: {
                        count: data?.category_count_data?.Question?.count,
                        percentage: data?.category_count_data?.Question?.percentage,
                    },
                    spam: {
                        count: data?.category_count_data?.Spam?.count,
                        percentage: data?.category_count_data?.Spam?.percentage,
                    },
                    totalReviews: {
                        count: data?.category_count_data?.['Total Reviews']?.count,
                        percentage: data?.category_count_data?.['Total Reviews']?.percentage,
                    },
                });

                setSourceData({
                    playStore: {
                        count: data?.source_count_data?.play_store?.count,
                        percentage: data?.source_count_data?.play_store?.percentage,
                    },
                    appStore: {
                        count: data?.source_count_data?.app_store?.count,
                        percentage: data?.source_count_data?.app_store?.percentage,
                    },
                    csv: {
                        count: data?.source_count_data?.csv?.count,
                        percentage: data?.source_count_data?.csv?.percentage,
                    },
                    api: {
                        count: data?.source_count_data?.api?.count,
                        percentage: data?.source_count_data?.api?.percentage,
                    },
                    g2: {
                        count: data?.source_count_data?.g2?.count,
                        percentage: data?.source_count_data?.g2?.percentage,
                    },
                });

                setCsat(data.csat);
                setNps(data.nps);

                setNpsDistribution({
                    Promoters: {
                        count: data?.nps_distribution?.Promoters?.count,
                        percentage: data?.nps_distribution?.Promoters?.percentage,
                    },
                    Detractors: {
                        count: data?.nps_distribution?.Detractors?.count,
                        percentage: data?.nps_distribution?.Detractors?.percentage,
                    },
                    Passives: {
                        count: data?.nps_distribution?.Passives?.count,
                        percentage: data?.nps_distribution?.Passives?.percentage,
                    },
                });

                setNpsData(data?.monthly_nps);
                setMonths(data?.nps_by_source?.sorted_months);
                setMonthlyNPSData(data?.nps_by_source?.source_results);
                setPromotersWordCloud(data?.promoters_word_cloud || []);
                setDetractorsWordCloud(data?.detractor_word_cloud || []);
                
                const extractedEmpathies = data?.empathies;

                setEmpathyState({
                    Says: {
                        title: 'SAYS',
                        question: 'What do you hear them saying? What do they talk about?',
                        data: extractedEmpathies?.Says?.data || []
                    },
                    Thinks: {
                        title: 'THINKS',
                        question: 'What are they worried/excited about? What are they thinking about?',
                        data: extractedEmpathies?.Thinks?.data || []
                    },
                    Does: {
                        title: 'DOES',
                        question: 'What are their daily activities (at home or at work)?',
                        data: extractedEmpathies?.Does?.data || []
                    },
                    Feels: {
                        title: 'FEELS',
                        question: 'How do they feel on a daily basis?',
                        data: extractedEmpathies?.Feels?.data || []
                    },
                    Pain: {
                        title: 'PAIN',
                        question: 'What are their frustrations and challenges?',
                        data: extractedEmpathies?.Pain?.data || []
                    },
                    Gain: {
                        title: 'GAIN',
                        question: 'What are their goals? What makes them happy?',
                        data: extractedEmpathies?.Gain?.data || []
                    }
                });


                setInterval(() => {
                    setLoading(false);
                }, 1000);
            }
        }
    }, [data]);


    return (
        <>
            {loading ? (
                <SkeletonDashboard />
            ) : !hasApps && (
                <Box sx={{
                    flexGrow: 1,
                    p: 3,
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: "100%",
                    marginTop: "0%",
                    textAlign: "center",
                    // backgroundColor: "#f9f9f9",
                    minHeight: "90vh",
                }}
                >
                    <Paper sx={{
                        p: { xs: 3, sm: 4, md: 5 },
                        // backgroundColor: "#fff",
                        backgroundColor: "#37517e",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        maxWidth: "800px",
                        width: "100%",
                    }}
                    >
                        <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#fff', fontWeight: 700 }}>
                            FeedbackQ
                        </Typography>
                        <Typography variant="h5" component="p" sx={{ mt: 2, mb: 6, color: '#ffffff99', fontSize: { xs: '20px', sm: '24px' }, fontWeight: 500 }}>
                            Welcome to Your Feedback Engine Co-pilot!
                        </Typography>
                        <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} md={11}>
                                {/* <Typography variant="body1" component="p" sx={{ mb: 2, color: 'text.secondary' , textAlign: 'left'}}>
                                    <strong>Empower Your Insights</strong><br />
                                    Unlock the potential of your feedback with AI-powered analysis and prioritization. Your journey to smarter decision-making starts here!
                                </Typography> */}

                                <Typography
                                    variant="h5" // You can use other variants if preferred
                                    component="p"
                                    sx={{ mb: 2, color: '#fff', textAlign: 'center' }}
                                >
                                    <strong>Empower Your Insights</strong>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{ mb: 2, color: '#ffffff99', textAlign: 'left', px: { xs: 2, sm: 4 } }}
                                >
                                    Unlock the potential of your feedback with AI-powered analysis and prioritization. Your journey to smarter decision-making starts here!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={11}>
                                {/* <Typography variant="body1" component="p" sx={{ mb: 2, color: 'text.secondary', textAlign: 'left' }}> */}
                                <Typography
                                    variant="h5" // You can use other variants if preferred
                                    component="p"
                                    sx={{ mb: 2, color: '#fff', textAlign: 'center' }}
                                >
                                    <strong>Seamless Integration</strong><br />
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{ mb: 2, color: '#ffffff99', textAlign: 'left', px: { xs: 2, sm: 4 } }}
                                >
                                    Effortlessly manage and analyze feedback from multiple sources. Keep track of what's important and drive your product forward with data-driven insights.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {/* <Typography variant="body1" component="p" sx={{ color: 'text.secondary' , textAlign: 'left'}}> */}
                                <Typography
                                    variant="h6" // You can use other variants if preferred
                                    component="p"
                                    sx={{ mb: 2, color: '#fff', textAlign: 'center', background: '#47b2e4', borderRadius: 50, px: 4, py: 2, width: 200 }}
                                >

                                    <Link to="/source" style={{ textDecoration: 'none', color: '#fff' }}>
                                        Get Started<br />
                                    </Link>

                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={11}>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{ mb: 2, color: '#ffffff99', textAlign: 'left', px: { xs: 2, sm: 4 } }}
                                >
                                    Navigate through our intuitive interface to begin categorizing, prioritizing, and making the most of your feedback. Your co-pilot is here to guide you every step of the way!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            )}
            {(hasApps && !loading) &&
                <Dashboard 
                    sourceData={sourceData}
                    csat={csat}
                    nps={nps}
                    npsDistribution={npsDistribution} 
                    npsData={npsData}
                    months={months}
                    monthlyNPSData={monthlyNPSData}
                    promotersWordCloud={promotersWordCloud}
                    detractorsWordCloud={detractorsWordCloud}
                    categoryData={categoryData}
                    empathyState={empathyState}
                />
            }
        </>
    );
};

export default TitleSection;
