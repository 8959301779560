import { Card, CardContent, Typography, Paper } from "@mui/material";
import WordCloud from 'react-d3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);
export const WordCloudCard = ({ title, words=[] }) => (
  <Paper elevation={3} sx={{ height: '100%', p: 2 }}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    <WordCloud 
      data={words} 
      width={500}
      height={300}
      font="Times"
      fontStyle="italic"
      fontWeight="bold"
      fontSize={(word) => Math.log2(word.value) * 10}
      spiral="rectangular"
      rotate={(word) => word.value % 360}
      padding={5}
      random={Math.random}
      fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
    />
</Paper>
)