import { Card, CardContent, Typography, Box } from "@mui/material";
import csvIcon from '../../assets/icons/csv-icon.png';
import playstoreIcon from '../../assets/icons/game.png';
import appstoreIcon from '../../assets/icons/appstore.png';
import apiIcon from '../../assets/icons/api-icon.png';
import unknownIcon from '../../assets/icons/unknown-mail.png';
import g2Icon from '../../assets/icons/g2-icon.png';

const CardComponentWithImage = ({ title, value, percentage }) => {
    let image;

    switch(title){
        case 'CSV':
            image = csvIcon;
            break;
        case 'Play Store':
            image = playstoreIcon;
            break;
        case 'App Store':
            image = appstoreIcon;
            break;
        case 'API':
            image = apiIcon;
            break;
        case 'G2':
            image = g2Icon;
            break
        default:
            image = unknownIcon;
            break;
    }

    return (
        <Card 
            className="shadow-lg relative" 
            sx={{ 
                borderRadius: 2, 
                padding: 2, 
                minWidth: 200, 
                textAlign: 'center',
                overflow: 'visible',
                pt: 2,
            }}
        >
            <Box 
                className="absolute" 
                sx={{
                    top: -35,
                    left: '50%', 
                    transform: 'translateX(-50%)',
                    backgroundColor: '#fff',
                    borderRadius: '50%',
                    padding: 1,
                    boxShadow: 3,
                    zIndex:3
                }}
            >
                <img src={image} alt={title} className="w-12 h-12" />
            </Box>

            <CardContent sx={{ pt: 5 }}> {/* Added padding to push content down */}
                <Typography variant="h6" className="font-bold text-gray-700">
                    {title}
                </Typography>
                <Box className="mt-4">
                    <Typography variant="h4" className="font-bold text-blue-600">
                        {value}
                    </Typography>
                    <Typography variant="body2" className={`mt-2 ${percentage >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                        {percentage >= 0 ? `+${percentage}%` : `${percentage}%`}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default CardComponentWithImage;
