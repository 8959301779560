import * as React from "react";
import { useState } from 'react'
import {
  Box,
  Button,
  List,
  Drawer,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel
} from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import {
  ListItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SidebarListItem from "../SidebarListItem";
import Icons from "../Icons";
import { Link } from "react-router-dom";
import PlayGroundDropDown from "../Dashboard/PlayGround";
import logo from '../../assets/img/logo.png';
// import ReviewsLoader from './Loaders/FetchingReviewsLoader'; //loader
// import { useFeedbackQContext } from "../context/FeedbackQ";
import { useFeedbackQContext } from "../../context/FeedbackQ";
import AnalyzeReviewsProgress from '../Loaders/AnalyzeReviewsProgress'; //progress
import AccountInfoDrawer from "../AccountInfo/AccountInfoDrawer";
import ReviewsLoader from "../Loaders/FetchingReviewsLoader"; //loader
// import FetchingReviewsLoader from "../svg/FetchReviewLoader";
// import AnalyseReviewLoader from "../svg/AnalyseReviewLoader";
// import { useFeedbackQContext } from "../context/FeedbackQ";
import { DrawerHeader, StyledInputBase } from "../../muitheme/StyledComponents";
import { MessageSquare } from 'lucide-react'
import { fetchAPISourceToken } from '../../api/API';
import axios from 'axios';

const SideBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  // const [loading,setLoading] = React.useState(true) ;
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState('')
  const [npsScore, setNpsScore] = useState(5)
  const [apiKey, setApiKey] = useState()


    const getAPIDetailes = async () => {
      try {
        const dashboard_id = localStorage.getItem("dashboardId");
        const response = await fetchAPISourceToken(dashboard_id);
        setApiKey(response.api_key);
      } catch (error) {
        console.log(error);
      }
    };
    


  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStopAnalyzing = () => {
    setIsAnalyzing(false);
    setProgressMessage('Analyzing reviews stopped.');
  };

  const handleOpen = () => setIsOpen(true)
 

  const { isAnalyzing,
    setIsAnalyzing,
    progressMessage,
    setProgressMessage,
    loading,
    name,
    setName
   } = useFeedbackQContext();
   getAPIDetailes();
  // setLoadingData("Fetching Reviews from play store. Please wait..");
  
  const handleButtonClose = () => setIsOpen(false)

  const getFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Feedback:', feedback)
    console.log("Apikey is "+ apiKey)
    const url = "https://feedbackq-service.insightq.ai/api/v1/reviews"
    const auth = "Bearer "+apiKey
    const headers = {"Content-Type": "application/json", "x-api-key": apiKey, "Authorization": auth}
    const payload = [
      {
          "content": feedback,
          "date_time": getFormattedDate(),
          "user_name": name,
          "rating": npsScore
      }]
      axios.post(url, payload, { headers })
        .then(response => console.log(response.data))
        .catch(error => console.error(error));

    // Reset form and close drawer
    setFeedback('')
    handleButtonClose()
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 260,
            boxSizing: "border-box",
            color: "black",
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: "#37517e", // Light background color
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow for depth
            mb: 2, // Margin-bottom to separate from other content
          }}
        >
          <img src={logo} alt="Logo" style={{ height: "40px", width: "auto" }} />
        </Box>
        <DrawerHeader>
          <Typography
            variant="div"
            sx={{ marginLeft: "5px", display: "flex", gap: "10px", alignItems: "center" }}
          >
            {/* <Avatar {...stringAvatar(name)} sx={{ width: 24, height: 24 }} /> */}
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px", // adjust the width as necessary
                fontWeight: 700,
                color: '#37517e',
                align: 'center'
              }}
            >
              {name}
            </Typography>
          </Typography>


          <UnfoldMoreIcon
            style={{ marginRight: "0px", cursor: "pointer" }}
            id="dropdown-button"
            aria-controls={anchorEl ? "dropdown-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? "true" : undefined}
            onClick={handleClick}
          />
        </DrawerHeader>
        <List>
          <ListItem>
            <StyledInputBase
              endAdornment={
                <SearchIcon sx={{ marginRight: 0, color: "black" }} />
              }
              placeholder="Search…"
            />
          </ListItem>
          <Link to="/" style={{ textDecoration: "none", color: '#37517e' }}>
            <SidebarListItem icon={Icons["homeIcon"]} listItem={"Home"} />
          </Link>
          {/* <Link
            to="/source"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidebarListItem
              icon={Icons["inboxIcon"]}
              listItem={"Data sources"}
            />
          </Link> */}
          <Link
            to="/reviews"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["snippet"]} listItem={"All reviews"} />
          </Link>
          <Link
            to="/themes"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["themes"]} listItem={"Themes"} />
          </Link>
          <Link
            to="/ask-q"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["askQ"]} listItem={"AskQ"} />
          </Link>
          <Link
            to="/rules"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["rules"]} listItem={"Rules"}  />
          </Link>
          <Link
            to="/book-call"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["bookcall"]} listItem={"Book Your Call"}  />
          </Link>
        </List>
        <Box sx={{ width: '100%', flexGrow: 1 }} />
        <Box sx={{ width: '100%', height: '150px', position: 'absolute', bottom: "140px" }}>
          {/* progress */}
          {isAnalyzing && (
            <AnalyzeReviewsProgress
              message={progressMessage}
              isAnalyzing={isAnalyzing}
              onStop={handleStopAnalyzing}
            />
            // <AnalyseReviewLoader />
          )}
        </Box>
        {/* <DrawerFooter>
          
          <IconButton sx={{ color: "black", gap: "5px" }} />
        </DrawerFooter> */} 
       
        <Box sx={{ flexGrow: .9 }} />{/* Spacer to push the Data sources link to the bottom */}
         <Box> 
        {loading && <ReviewsLoader message="Fetching reviews..." />}
        {/* { loading && <FetchingReviewsLoader />} */}
        </Box>
        <Link to="/source" style={{ textDecoration: "none", color: "#37517e" }}>
          <SidebarListItem icon={Icons["inboxIcon"]} listItem={"Data sources"} />
        </Link>
       {/* earlier here was a footer */}
        <SidebarListItem
          icon={Icons["accInfo"]}
          listItem={"Usage"}
          onClick={toggleDrawer(true)}
        />

        {/* <DrawerFooter>
          <ProfileAvatar name={name} />
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton sx={{ color: "black" }}>
              <HelpOutlineIcon />
            </IconButton>
            <SettingsMenu />
          </Box>
        </DrawerFooter> */}
      </Drawer>
      <PlayGroundDropDown
        anchorEl={anchorEl}
        handleClose={handleClose}
        setName={setName}
      />
      <AccountInfoDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />

      {/* Feedback Button */}
      <Box
        sx={{
          width: 0, // Adjust this value according to your sidebar width
          height: '100vh',
          position: 'relative',
          backgroundColor: '#f0f0f0', // Background color for the sidebar
        }}
      >

      {/* Add button to the right side */}
      <Button
        variant="contained"
        startIcon={<MessageSquare />}
        sx={{
          position: 'fixed',
          right: 20,
          top: '50%',
          transform: 'translateY(-50%) rotate(-90deg)',
          transformOrigin: 'right center',
          zIndex: 1000,
        }}
        onClick={handleOpen}
      >
        Provide Feedback
      </Button> 
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleButtonClose}
      >
        <Box
          sx={{
            width: 500,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Provide Feedback
          </Typography>
          <TextField
            label="Your Feedback"
            multiline
            rows={4}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">
              How likely are you to recommend our product to a friend?
              Rate the App
            </FormLabel>
            <RadioGroup
              row
              value={npsScore}
              onChange={(e) => setNpsScore(Number(e.target.value))}
            >
              {[1, 2, 3, 4, 5].map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={value.toString()}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Submit Feedback
          </Button>
        </Box>
      </Drawer>
    </Box>
    </Box>

  );
};


export default SideBar;
