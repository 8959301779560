import { Box, Grid } from "@mui/material";
import { dashboardCardData } from "./dummyData";
import CardComponent from "./Dashboard/CardComponent";
import CardComponentWithImage from "./Dashboard/CardComponentWithImage";
import HeaderSection from "./Dashboard/HeaderSection";
import useCardGrid, { useCardGrid2 } from "../hooks/useCardGrid";
import CustomerSatisfactionScore from "./Dashboard/CustomerSatisfactionScore";
import NetPromoterScore from "./Dashboard/NetPromoterScore";
import ProgressBlock from "./Dashboard/ProgressBlock";
import NPXChart from "./Dashboard/NPXChart";
import NPXTradeComparison from "./Dashboard/NPXTradeComparison";
import { WordCloudCard } from "./utils/WordCloudCard";
import EmpathyCharts from "./charts/EmpathyCharts";
import { useEffect, useState } from "react";

const Dashboard = ({ sourceData, csat, nps, npsDistribution, npsData, monthlyNPSData, months, promotersWordCloud, detractorsWordCloud, categoryData, empathyState }) => {
    const [imageDashboardCardData, setImageDashboardCardData] = useState([{ title: "CSV", value: "0", percentage: 0 },
    { title: "App Store", value: "0", percentage: 0 },
    { title: "Play Store", value: "0", percentage: 0 },
    { title: "API", value: "0", percentage: 0 },
    { title: "G2", value: "0", percentage: 0 }])
    const [dashboardCardData, setDashboardCardData] = useState(
        [{ title: "Total Reviews", value: 0, percentage: 0 },
        { title: "New Feature", value: 0, percentage: 0 },
        { title: "Bug Reports", value: 0, percentage: 0 },
        { title: "Questions", value: 0, percentage: 0 },
        { title: "Appreciations", value: 0, percentage: 0 },
        { title: "Spams", value: 0, percentage: 0 },])

        useEffect(() => {
            const newDashboardCardData = [
                { title: "Total Reviews", value: categoryData?.totalReviews?.count, percentage: categoryData?.totalReviews?.percentage },
                { title: "New Feature", value: categoryData?.newFeatureRequest?.count, percentage: categoryData?.newFeatureRequest?.percentage },
                { title: "Bug Reports", value: categoryData?.bugReport?.count, percentage: categoryData?.bugReport?.percentage },
                { title: "Questions", value: categoryData?.question?.count, percentage: categoryData?.question?.percentage },
                { title: "Appreciations", value: categoryData?.appreciation?.count, percentage: categoryData?.appreciation?.percentage },
                { title: "Spams", value: categoryData?.spam?.count, percentage: categoryData?.spam?.percentage },
            ];
            
            setDashboardCardData(newDashboardCardData);
        }, [categoryData]);
        
        useEffect(() => {
            const newImageDashboardCardData = [
                { title: "CSV", value: sourceData?.csv?.count, percentage: sourceData?.csv?.percentage },
                    { title: "App Store", value: sourceData?.appStore?.count, percentage: sourceData?.appStore?.percentage },
                    { title: "Play Store", value: sourceData?.playStore?.count, percentage: sourceData?.playStore?.percentage },
                    { title: "API", value: sourceData?.api?.count, percentage: sourceData?.api?.percentage },
                    { title: "G2", value: sourceData?.g2?.count, percentage: sourceData?.g2?.percentage },
                ];

                setImageDashboardCardData(newImageDashboardCardData);
        }, [sourceData]);
        const firstCardRow = useCardGrid(dashboardCardData, CardComponent);
        const secondCardRow = useCardGrid2(imageDashboardCardData, CardComponentWithImage);
        console.log("empathyState :: ", empathyState);
        return (
        <>
            <Box
                className="bg-white min-h-screen"
                sx={{
                    padding: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                <Box className='container mx-auto px-4 sm:px-6 md:px-8'>
                    <HeaderSection />
                    {firstCardRow}
                    {secondCardRow}
                    <Grid container spacing={4} marginY={3}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <CustomerSatisfactionScore value={csat} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <NetPromoterScore score={nps} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <ProgressBlock 
                                detractors={npsDistribution?.Detractors}
                                passives={npsDistribution?.Passives}
                                promoters={npsDistribution?.Promoters}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NPXChart npsData={npsData} />
                        </Grid>
                        <Grid item xs={12}>
                            <NPXTradeComparison months={months} monthlyNPSData={monthlyNPSData} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WordCloudCard title="Promoters" words={promotersWordCloud} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WordCloudCard title="Detractors" words={detractorsWordCloud} />
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                                <EmpathyCharts empathyState={empathyState }/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Dashboard;