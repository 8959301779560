// import React, { useState } from 'react';
// import { Paper, Typography, Box } from '@mui/material';

// const initialNotes = [
//   { id: '1', content: 'What really matters', section: 'think' },
//   { id: '2', content: 'Worries/Aspirations?', section: 'think' },
//   { id: '3', content: 'Environment', section: 'see' },
//   { id: '4', content: 'Social Media', section: 'see' },
//   { id: '5', content: 'What friends say', section: 'hear' },
//   { id: '6', content: 'What family say', section: 'hear' },
//   { id: '7', content: 'Appearance', section: 'say' },
//   { id: '8', content: 'Attitude in public', section: 'say' },
//   { id: '9', content: 'Fears', section: 'pains' },
//   { id: '10', content: 'Frustrations', section: 'pains' },
//   { id: '11', content: 'Wants/Needs', section: 'gains' },
//   { id: '12', content: 'Measure of success', section: 'gains' },
// ];

// export default function EmpathyMap() {
//   const [notes, setNotes] = useState(initialNotes);

//   const onDragStart = (e, noteId) => {
//     e.dataTransfer.setData('noteId', noteId);
//   };

//   const onDragOver = (e) => {
//     e.preventDefault();
//   };

//   const onDrop = (e, targetSection) => {
//     e.preventDefault();
//     const noteId = e.dataTransfer.getData('noteId');
//     const updatedNotes = notes.map(note => 
//       note.id === noteId ? { ...note, section: targetSection } : note
//     );
//     setNotes(updatedNotes);
//   };

//   const renderNotes = (section) => {
//     return notes
//       .filter(note => note.section === section)
//       .map(note => (
//         <Paper
//           key={note.id}
//           elevation={3}
//           className="p-2 m-2 cursor-move"
//           draggable
//           onDragStart={(e) => onDragStart(e, note.id)}
//           sx={{
//             backgroundColor: "#f2f294"
//           }}
//         >
//           <Typography variant="body2">{note.content}</Typography>
//         </Paper>
//       ));
//   };

//   return (
//     <div className="w-full h-screen bg-white p-4">
//       <div className="w-full h-full grid grid-cols-2 grid-rows-3 gap-4">
//         <div className="relative col-span-2 row-span-2 border-2 border-gray-300">
//           <div className="absolute inset-0" style={{ clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }}>
//             <div 
//               className="absolute top-0 left-0 w-1/2 h-1/2 border-r-2 border-b-2 border-gray-300 p-2" onDragOver={onDragOver} onDrop={(e) => onDrop(e, 'think')}>
//               <Typography variant="h6" className="text-pink-500 font-bold">Think and Feel?</Typography>
//               {renderNotes('think')}
//             </div>
//             <div className="absolute top-0 right-0 w-1/2 h-1/2 border-l-2 border-b-2 border-gray-300 p-2" onDragOver={onDragOver} onDrop={(e) => onDrop(e, 'see')}>
//               <Typography variant="h6" className="text-blue-500 font-bold">See?</Typography>
//               {renderNotes('see')}
//             </div>
//             <div className="absolute bottom-0 left-0 w-1/2 h-1/2 border-r-2 border-t-2 border-gray-300 p-2" onDragOver={onDragOver} onDrop={(e) => onDrop(e, 'hear')}>
//               <Typography variant="h6" className="text-purple-500 font-bold">Hear?</Typography>
//               {renderNotes('hear')}
//             </div>
//             <div className="absolute bottom-0 right-0 w-1/2 h-1/2 border-l-2 border-t-2 border-gray-300 p-2" onDragOver={onDragOver} onDrop={(e) => onDrop(e, 'say')}>
//               <Typography variant="h6" className="text-green-500 font-bold">Say and Do?</Typography>
//               {renderNotes('say')}
//             </div>
//           </div>
//         </div>
//         <div className="border-2 border-gray-300 p-2 bg-red-100" onDragOver={onDragOver} onDrop={(e) => onDrop(e, 'pains')}>
//           <Typography variant="h6" className="text-blue-700 font-bold">Pains</Typography>
//           {renderNotes('pains')}
//         </div>
//         <div className="border-2 border-gray-300 p-2 bg-green-100" onDragOver={onDragOver} onDrop={(e) => onDrop(e, 'gains')}>
//           <Typography variant="h6" className="text-orange-500 font-bold">Gains</Typography>
//           {renderNotes('gains')}
//         </div>
//       </div>
//       <Box className="relative w-full max-w-2xl aspect-[16/9] mx-auto my-8 bg-yellow-300 border-2 border-purple-600 overflow-hidden">
//       {/* Top Left Section */}
//       <Box className="absolute top-0 left-0 w-1/2 h-1/2 flex items-start justify-start p-4 z-10">
//         <Typography variant="h6" className="text-black">Top Left Data</Typography>
//       </Box>

//       {/* Top Right Section */}
//       <Box className="absolute top-0 right-0 w-1/2 h-1/2 flex items-start justify-end p-4 z-10">
//         <Typography variant="h6" className="text-black">Top Right Data</Typography>
//       </Box>

//       {/* Bottom Left Section */}
//       <Box className="absolute bottom-0 left-0 w-1/2 h-1/2 flex items-end justify-start p-4 z-10">
//         <Typography variant="h6" className="text-black">Bottom Left Data</Typography>
//       </Box>

//       {/* Bottom Right Section */}
//       <Box className="absolute bottom-0 right-0 w-1/2 h-1/2 flex items-end justify-end p-4 z-10">
//         <Typography variant="h6" className="text-black">Bottom Right Data</Typography>
//       </Box>

//       {/* Diagonal Lines */}
//       <Box className="absolute inset-0 pointer-events-none">
//         <Box className="absolute top-0 left-0 w-full h-full border-t-2 border-purple-600 rotate-45 origin-top-left"></Box>
//         <Box className="absolute top-0 right-0 w-full h-full border-t-2 border-purple-600 -rotate-45 origin-top-right"></Box>
//       </Box>
//     </Box>
//     </div>
//   );
// }

import React from 'react'
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Grid, 
  Avatar,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material'
import { User } from 'lucide-react'

const empathyMapData = [
  {
    title: 'SAYS',
    question: 'What do you hear them saying? What do they talk about?',
    data: [
      '"I need a solution that saves me time."',
      '"The current process is too complicated."',
      '"I wish there was an easier way to do this."'
    ]
  },
  {
    title: 'THINKS',
    question: 'What are they worried/excited about? What are they thinking about?',
    data: [
      'Concerned about keeping up with industry trends',
      'Excited about potential efficiency improvements',
      'Thinking about how to balance work and personal life'
    ]
  },
  {
    title: 'DOES',
    question: 'What are their daily activities (at home or at work)?',
    data: [
      'Attends multiple meetings throughout the day',
      'Manages a team of 5-10 people',
      'Spends time troubleshooting technical issues'
    ]
  },
  {
    title: 'FEELS',
    question: 'How do they feel on a daily basis?',
    data: [
      'Overwhelmed by the amount of work',
      'Motivated to make a difference in their role',
      'Frustrated with inefficient processes'
    ]
  },
  {
    title: 'PAIN',
    question: 'What are their frustrations and challenges?',
    data: [
      'Difficulty in prioritizing tasks',
      'Lack of clear communication within the team',
      'Outdated tools and technologies'
    ]
  },
  {
    title: 'GAIN',
    question: 'What are their goals? What makes them happy?',
    data: [
      'Streamlining workflows and processes',
      'Achieving work-life balance',
      'Recognition for innovative ideas and solutions'
    ]
  }
]

const QuadrantCard = ({ section }) => {
  console.log("section :: ", section)
  return <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardContent>
      <Typography variant="h6" component="h2" gutterBottom>
        {section?.title}
      </Typography>
      <Typography variant="body2" color="text.secondary" paragraph>
        {section?.question}
      </Typography>
      {section?.data?.map((item, i) => (
        <Typography key={i} variant="body2" paragraph>
          • {item}
        </Typography>
      ))}
    </CardContent>
  </Card>
}

export default function EmpathyMap({ empathyState }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  console.log("Does :: ",empathyState?.Does);
  return (
    <Box sx={{ p: 3, bgcolor: 'background.paper'}}>
      <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
        Empathy Map
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <QuadrantCard section={empathyState?.Says} /> {/* SAYS */}
            </Grid>
            <Grid item xs={12} md={6}>
              <QuadrantCard section={empathyState?.Thinks} /> {/* THINKS */}
            </Grid>
            <Grid item xs={12} md={6}>
              <QuadrantCard section={empathyState?.Does} /> {/* DOES */}
            </Grid>
            <Grid item xs={12} md={6}>
              <QuadrantCard section={empathyState?.Feels} /> {/* FEELS */}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', bgcolor: 'secondary.light', color: 'whitesmoke' }}>
                <CardContent>
                  <Typography variant="h6" component="h2" gutterBottom sx={{ color: "white"}}>
                    {empathyState?.Pain?.title} {/* PAIN */}
                  </Typography>
                  <Typography variant="body2" color="white" paragraph>
                    {empathyState?.Pain?.question}
                  </Typography>
                  {empathyState?.Pain?.data?.map((item, i) => (
                    <Typography key={i} variant="body2" paragraph>
                      • {item}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', bgcolor: 'secondary.light', color: 'whitesmoke' }}>
                <CardContent>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {empathyState?.Gain?.title} {/* GAIN */}
                  </Typography>
                  <Typography variant="body2" color="white" paragraph>
                    {empathyState?.Gain?.question}
                  </Typography>
                  {empathyState?.Gain?.data?.map((item, i) => (
                    <Typography key={i} variant="body2" paragraph>
                      • {item}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper 
            elevation={3}
            sx={{ 
              p: 2, 
              bgcolor: 'primary.main', 
              color: 'primary.contrastText',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Avatar sx={{ width: 80, height: 80, bgcolor: 'primary.light', mb: 2 }}>
              <User size={48} />
            </Avatar>
            <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
              WHO?
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              Name/Role/Persona
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              Tech-savvy Project Manager
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              Age: 35-45
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              Experience: 10+ years
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}