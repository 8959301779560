export const dummyData = {
    "NewFeatureRequests": [
        {
            "Theme": "Positive Feedback",
            "Summary": "Users expressing satisfaction with the app",
            "EmotionalAnalysis": {
                "Joy": 4,
                "Sadness": 0,
                "Anger": 0,
                "Fear": 0,
                "Disgust": 0,
                "Surprise": 1,
                "Trust": 3,
                "Anticipation": 2
            },
            "SentimentalAnalysis": {
                "Positive": 8,
                "Neutral": 2,
                "Negative": 0
            },
            "Reviews": [
                {
                    "Review": "Good",
                    "EmotionalAnalysis": {
                        "Joy": 1,
                        "Sadness": 0,
                        "Anger": 0,
                        "Fear": 0,
                        "Disgust": 0,
                        "Surprise": 0,
                        "Trust": 1,
                        "Anticipation": 1
                    },
                    "SentimentalAnalysis": {
                        "Positive": 3,
                        "Neutral": 2,
                        "Negative": 0
                    }
                },
                {
                    "Review": "VERY NICE SONY LIV APP",
                    "EmotionalAnalysis": {
                        "Joy": 1,
                        "Sadness": 0,
                        "Anger": 0,
                        "Fear": 0,
                        "Disgust": 0,
                        "Surprise": 1,
                        "Trust": 2,
                        "Anticipation": 1
                    },
                    "SentimentalAnalysis": {
                        "Positive": 4,
                        "Neutral": 1,
                        "Negative": 0
                    }
                },
                {
                    "Review": "Best....",
                    "EmotionalAnalysis": {
                        "Joy": 1,
                        "Sadness": 0,
                        "Anger": 0,
                        "Fear": 0,
                        "Disgust": 0,
                        "Surprise": 0,
                        "Trust": 1,
                        "Anticipation": 1
                    },
                    "SentimentalAnalysis": {
                        "Positive": 3,
                        "Neutral": 2,
                        "Negative": 0
                    }
                },
                {
                    "Review": "Best",
                    "EmotionalAnalysis": {
                        "Joy": 1,
                        "Sadness": 0,
                        "Anger": 0,
                        "Fear": 0,
                        "Disgust": 0,
                        "Surprise": 0,
                        "Trust": 1,
                        "Anticipation": 1
                    },
                    "SentimentalAnalysis": {
                        "Positive": 3,
                        "Neutral": 2,
                        "Negative": 0
                    }
                }
            ]
        }
    ],
    "Bugs": [
        {
            "Theme": "Negative Feedback",
            "Summary": "Users expressing dissatisfaction with the app",
            "EmotionalAnalysis": {
                "Joy": 0,
                "Sadness": 2,
                "Anger": 3,
                "Fear": 1,
                "Disgust": 2,
                "Surprise": 1,
                "Trust": 0,
                "Anticipation": 1
            },
            "SentimentalAnalysis": {
                "Positive": 0,
                "Neutral": 2,
                "Negative": 8
            },
            "Reviews": [
                {
                    "Review": "Charges very high",
                    "EmotionalAnalysis": {
                        "Joy": 0,
                        "Sadness": 1,
                        "Anger": 1,
                        "Fear": 0,
                        "Disgust": 1,
                        "Surprise": 0,
                        "Trust": 0,
                        "Anticipation": 0
                    },
                    "SentimentalAnalysis": {
                        "Positive": 0,
                        "Neutral": 1,
                        "Negative": 2
                    }
                },
                {
                    "Review": "Worst app",
                    "EmotionalAnalysis": {
                        "Joy": 0,
                        "Sadness": 1,
                        "Anger": 1,
                        "Fear": 0,
                        "Disgust": 1,
                        "Surprise": 1,
                        "Trust": 0,
                        "Anticipation": 0
                    },
                    "SentimentalAnalysis": {
                        "Positive": 0,
                        "Neutral": 1,
                        "Negative": 2
                    }
                },
                {
                    "Review": "0 rateing not free",
                    "EmotionalAnalysis": {
                        "Joy": 0,
                        "Sadness": 0,
                        "Anger": 1,
                        "Fear": 1,
                        "Disgust": 1,
                        "Surprise": 0,
                        "Trust": 0,
                        "Anticipation": 0
                    },
                    "SentimentalAnalysis": {
                        "Positive": 0,
                        "Neutral": 0,
                        "Negative": 3
                    }
                },
                {
                    "Review": "Very very bad",
                    "EmotionalAnalysis": {
                        "Joy": 0,
                        "Sadness": 0,
                        "Anger": 1,
                        "Fear": 0,
                        "Disgust": 1,
                        "Surprise": 0,
                        "Trust": 0,
                        "Anticipation": 1
                    },
                    "SentimentalAnalysis": {
                        "Positive": 0,
                        "Neutral": 0,
                        "Negative": 3
                    }
                }
            ]
        },
        {
            "Theme": "Neutral/Unclear Feedback",
            "Summary": "Reviews that do not provide clear feedback or are neutral",
            "EmotionalAnalysis": {
                "Joy": 0,
                "Sadness": 0,
                "Anger": 0,
                "Fear": 0,
                "Disgust": 0,
                "Surprise": 0,
                "Trust": 0,
                "Anticipation": 0
            },
            "SentimentalAnalysis": {
                "Positive": 0,
                "Neutral": 5,
                "Negative": 0
            },
            "Reviews": [
                {
                    "Review": "Baban",
                    "EmotionalAnalysis": {
                        "Joy": 0,
                        "Sadness": 0,
                        "Anger": 0,
                        "Fear": 0,
                        "Disgust": 0,
                        "Surprise": 0,
                        "Trust": 0,
                        "Anticipation": 0
                    },
                    "SentimentalAnalysis": {
                        "Positive": 0,
                        "Neutral": 2,
                        "Negative": 0
                    }
                }
            ]
        }
    ]
};
// for review tables test purpose
export const reviews = Array.from({ length: 15 }, (_, index) => ({
    platform: index % 2 === 0 ? 'App Store' : 'Play Store',
    comment: `This is a dummy comment ${index + 1}. Lorem ipsum dolor sit amet.`,
    username: `user${index + 1}`,
    date: `2024-07-${20 + (index % 10)}`
}));

export const cardData = [
    { heading: "Total Snippets", content: 33612, sub: "9.6% of snippets" },
    { heading: "Feature Requests", content: 3220, sub: "3.2% of snippets" },
    { heading: "Bug Reports", content: 1245, sub: "1.2% of snippets" },
    { heading: "Questions", content: 381, sub: "0.6% of snippets" },
    { heading: "Appreciations", content: 11806, sub: "38.1% of snippets"}
];

export const cardData2 = [
    { content: 777066, sub: 'Total Reviews' }
];

export const pieChartData = [
    ["Category", "Percentage"],
    ["Positive", 50],
    ["Neutral", 30],
    ["Negative", 20],
];

export const areaChartData = [
    ["Month", "Feedback Count"],
    ["Jan", 120],
    ["Feb", 150],
    ["Mar", 180],
    ["Apr", 130],
    ["May", 160],
    ["Jun", 170],
    ["Jul", 140],
    ["Aug", 190],
    ["Sep", 200],
    ["Oct", 220],
    ["Nov", 210],
    ["Dec", 250],
];

export const topThemesData = [
    ['Theme', 'Count'],
    ['Feature Requests', 3220],
    ['Bug Reports', 2740],
    ['User Feedback', 1500],
    ['Enhancements', 1200],
    ['Appreciations', 800],
];

export const dummyTheme = {
    _id: "60f6b3f2d64a8f001c8b4567", 
    type: "theme",
    theme: "Irrelevant Feedback or Irregular Comments that Distract from Genuine Insights",
    summary: "The presence of irrelevant or incoherent feedback disrupts the landscape of genuine user insights. Some reviews lack substantive details, rendering them non-informative and failing to contribute valuable perspectives on the game’s performance or features. Such comments may originate from a misunderstanding of the game's mechanics or general irrelevance, indicating a disconnect from the needs of users seeking to engage with constructive feedback.",
    EmotionalAnalysis: "Disgust",
    SentimentalAnalysis: "Negative",
    reviews: [
        "60f6b3f2d64a8f001c8b4568",
        "60f6b3f2d64a8f001c8b4569",
        "60f6b3f2d64a8f001c8b4570"
    ],
    category: "Spams",
    action_item: true,
    action_items: [
        "Develop clear guidelines and examples for providing constructive feedback that can be shared with users.",
        "Launch a communication campaign to educate users on the importance of relevant and constructive feedback.",
        "Create a feedback moderation system to identify and filter out irrelevant comments before they reach the service team.",
        "Engage with users through forums or social media to solicit insights on how they suggest improving feedback relevance."
    ],
    anomalies: [
        {
            Date: "2024-07-20",
            Review_Count: "2",
            Average_Daily_Count: "0.4",
            Significance: "An unexpected spike in review count",
            Possible_Reasons: "Potential promotional activities or a recent game update attracting attention",
            Suggested_Next_Steps: "Review marketing activities and community discussions around the game's updates."
        },
        {
            Date: "2024-07-25",
            Review_Count: "1",
            Average_Daily_Count: "0.4",
            Significance: "Low but notable review count following a busy week",
            Possible_Reasons: "Possible decrease in user interest post-update or lack of engagement from the community",
            Suggested_Next_Steps: "Gather direct user feedback to understand concerns and analyze engagement levels."
        }
    ],
    dev_activity: [
        "Implement a user interface feature that prompts users to categorize their feedback and provide specific details to improve relevance.",
        "Analyze feedback data to identify common themes in irrelevant or incoherent responses and adjust feedback forms accordingly.",
        "Develop a machine learning model to help automatically flag or prioritize feedback based on relevance and substance.",
        "Integrate a rating system for feedback quality, allowing users to upvote or downvote comments based on their helpfulness."
    ]
};

export const generateLoremIpsum = () => {
    return `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;
};

export const preBuiltQuestions = [
    "What are the main features of this product?",
    "How can I get started with this service?",
    "What are the common issues users face?",
    "How can I contact customer support?",
    "Are there any tutorials available?"
];

export const hardcodedOutputs = [
    {
        title: "Analysis 1",
        content: `
            ## Sample Output for "Analysis 1" - Analysis 1

            ### Overview

            This output is based on the rule set and title provided. It simulates a detailed analysis of app reviews from Play Store and App Store, including common themes, user sentiments, and key points for output 1.

            ### Example Analysis

            - **Rule 1:** Analysis based on Rule 1.
            - **Summary:** Review analysis for Rule 1.
            - **Insights:**
              - **Common Complaints:** Issues related to Rule 1.
              - **Sentiment:** Mixed or positive feedback for Rule 1.

            ### Key Takeaways

            - Address common complaints to improve user satisfaction for Rule 1.
            - Consider user suggestions for enhancing the app's features related to Rule 1.

            ### Conclusion

            This analysis provides a snapshot of user feedback based on the specified rule and title. Further in-depth analysis could provide more granular insights.

            **Note:** The above insights are fictional and meant for demonstration purposes.
        `
    },
    {
        title: "Analysis 2",
        content: `
            ## Sample Output for "Analysis 2" - Analysis 2

            ### Overview

            This output is based on the rule set and title provided. It simulates a detailed analysis of app reviews from Play Store and App Store, including common themes, user sentiments, and key points for output 2.

            ### Example Analysis

            - **Rule 2:** Analysis based on Rule 2.
            - **Summary:** Review analysis for Rule 2.
            - **Insights:**
              - **Common Complaints:** Issues related to Rule 2.
              - **Sentiment:** Mostly negative feedback for Rule 2.

            ### Key Takeaways

            - Improve user experience by addressing common issues related to Rule 2.
            - Consider implementing user feedback for better app functionality related to Rule 2.

            ### Conclusion

            This analysis provides a snapshot of user feedback based on the specified rule and title. Further analysis could provide additional insights.

            **Note:** The above insights are fictional and meant for demonstration purposes.
        `
    },
    {
        title: "Analysis 3",
        content: `
            ## Sample Output for "Analysis 3" - Analysis 3

            ### Overview

            This output provides an in-depth review of user feedback based on Rule 3. It highlights common themes and sentiments found in app reviews.

            ### Example Analysis

            - **Rule 3:** Analysis based on Rule 3.
            - **Summary:** Review analysis for Rule 3.
            - **Insights:**
              - **Common Complaints:** Problems related to Rule 3.
              - **Sentiment:** Generally positive feedback for Rule 3.

            ### Key Takeaways

            - Focus on addressing key issues related to Rule 3.
            - Enhance features based on user feedback for Rule 3.

            ### Conclusion

            This analysis is a representation of user feedback for Rule 3. Additional detailed insights can be obtained through further analysis.

            **Note:** The above insights are fictional and meant for demonstration purposes.
        `
    },
    {
        title: "Analysis 4",
        content: `
            ## Sample Output for "Analysis 4" - Analysis 4

            ### Overview

            This output analyzes user feedback related to Rule 4, offering insights into common themes and sentiments from app reviews.

            ### Example Analysis

            - **Rule 4:** Analysis based on Rule 4.
            - **Summary:** Review analysis for Rule 4.
            - **Insights:**
              - **Common Complaints:** Issues associated with Rule 4.
              - **Sentiment:** Mixed feedback for Rule 4.

            ### Key Takeaways

            - Address major complaints to improve user satisfaction related to Rule 4.
            - Use feedback to enhance Rule 4-related features.

            ### Conclusion

            This analysis provides a summary of feedback for Rule 4. More detailed insights can be obtained with deeper analysis.

            **Note:** The above insights are fictional and meant for demonstration purposes.
        `
    },
    {
        title: "Analysis 5",
        content: `
            ## Sample Output for "Analysis 5" - Analysis 5

            ### Overview

            This output presents an analysis based on Rule 5, summarizing common themes, sentiments, and key points from user reviews.

            ### Example Analysis

            - **Rule 5:** Analysis based on Rule 5.
            - **Summary:** Review analysis for Rule 5.
            - **Insights:**
              - **Common Complaints:** Concerns related to Rule 5.
              - **Sentiment:** Predominantly positive feedback for Rule 5.

            ### Key Takeaways

            - Tackle the primary issues related to Rule 5 to enhance user satisfaction.
            - Consider user suggestions for improving features related to Rule 5.

            ### Conclusion

            This analysis offers a glimpse into user feedback for Rule 5. Further exploration could yield more specific insights.

            **Note:** The above insights are fictional and meant for demonstration purposes.
        `
    }
];

export const dummyTheme2 = {
    "_id": {
        "$oid": "66b881bfb054526215f38a73"
    },
    "type": "theme",
    "theme": "Users Reporting High Frustration Due to Bugs and Poor Performance of the App",
    "summary": "Numerous reviews highlight significant dissatisfaction with the app's performance. Users mention issues like ads being too frequent and long, inability to access the app, and critical bugs affecting functionality, such as a bug related to video quality settings. Many express anger as they feel the app is not functioning as intended, leading to an overall negative experience. Reviews indicating that the app has 'very poor quality' or that it 'won't open' exhibit a deep level of frustration and disappointment among users, as they expected better performance for their investment.",
    "EmotionalAnalysis": "Anger",
    "SentimentalAnalysis": "Negative",
    "ToxicityLevel": "High",
    "reviews": [
        {
            "$oid": "66b5c006fb079c15b4f207a2"
        },
        {
            "$oid": "66b5c006fb079c15b4f207c0"
        },
        {
            "$oid": "66b5c006fb079c15b4f207c1"
        },
        {
            "$oid": "66b5c006fb079c15b4f207c2"
        },
        {
            "$oid": "66b5c006fb079c15b4f207c6"
        },
        {
            "$oid": "66b5c006fb079c15b4f207c7"
        }
    ],
    "category": "Bugs",
    "action_status": false,
    "anomaly": `
    ## Anomaly Details

    **Issue**: Users are experiencing critical bugs that significantly affect app functionality.

    **Details**:
    - Frequent app crashes
    - Issues with video quality settings
    - Ads are excessively long and frequent

    **Impact**:
    - Users are unable to access the app reliably
    - Overall negative user experience
    `,
    "action_item": [
        "Investigate the root cause of frequent app crashes",
        "Fix bugs related to video quality settings",
        "Reduce the frequency and length of ads"
    ],
    "dev_activity": [
        "Bug fixes for video quality settings",
        "Optimization of ad delivery system",
        "App stability improvements"
    ],
    "dashboard_id": "66b88133b054526215f38a72"
}

export const initialRules = [
    {
      id: '1',
      title: 'User Access Issue',
      content: 'Users are unable to access their accounts due to login issues. The problem appears to be related to recent changes in authentication protocols that may have inadvertently altered or disabled certain access permissions. This issue affects a significant number of users, leading to widespread frustration and a decrease in user satisfaction. Immediate attention is required to diagnose and resolve the authentication failures to restore normal access and prevent further user dissatisfaction.'
    },
    {
      id: '2',
      title: 'Data Synchronization Error',
      content: 'Data synchronization between the app and server is failing intermittently, causing data loss or duplication. This issue is particularly problematic during high-traffic periods when the system experiences heavy load. The synchronization errors have led to discrepancies between user data on different devices and the server, affecting the reliability and accuracy of the data presented to users. A thorough investigation is needed to identify the root cause and implement a robust solution to ensure seamless data synchronization.'
    },
    {
      id: '3',
      title: 'Performance Degradation',
      content: 'Users report slow performance when navigating through the app, especially during peak hours. The performance degradation is attributed to inefficient resource management and potential bottlenecks in the app’s architecture. This slow performance negatively impacts user experience, leading to longer load times and increased frustration. Optimization of the app’s performance and scalability is necessary to handle high user traffic and improve overall responsiveness.'
    },
    {
      id: '4',
      title: 'Notification Failures',
      content: 'Notifications are not being delivered to users as expected. This issue affects both email and in-app notifications, leading to missed updates and reduced engagement. The failures in notification delivery may be due to errors in notification settings, server issues, or misconfigurations in the notification service. Addressing this problem requires a comprehensive review of the notification system and implementation of fixes to ensure timely and reliable delivery of notifications.'
    },
    {
      id: '5',
      title: 'Payment Processing Errors',
      content: 'Errors occur during payment processing, leading to failed transactions and user frustration. The payment processing errors are causing significant issues with completing purchases, resulting in lost revenue and negative feedback from users. The errors may be linked to issues with payment gateway integration, validation errors, or transaction handling. A detailed examination of the payment processing workflow and troubleshooting of integration issues are essential to resolve this problem and restore a smooth payment experience.'
    },
    {
      id: '6',
      title: 'Security Vulnerabilities',
      content: 'Several security vulnerabilities have been identified that may compromise user data and app integrity. These vulnerabilities pose significant risks, including unauthorized access to sensitive information and potential data breaches. The identified security issues need to be addressed urgently to protect user data, ensure compliance with data protection regulations, and maintain the app’s reputation. Implementing security patches, conducting vulnerability assessments, and enhancing overall security measures are crucial steps to mitigate these risks.'
    },
    {
      id: '7',
      title: 'Feature Misalignment',
      content: 'The app features are not aligning well with user expectations and needs. Users have reported that certain features either do not function as intended or are missing from the app, leading to dissatisfaction and decreased usability. This misalignment could stem from inadequate user research or failure to address user feedback effectively. To address this issue, a detailed review of user feedback, feature usage, and user needs is required to realign the app features with user expectations and improve overall satisfaction.'
    }
];

export const apiData = {
    apiUrl: 'https://api.example.com/data',
    apiToken: 'AE2CFE25341859EA3566A559E4FBC52F7B0C5434B5B0938B18C0D806B2068ABC',
    curlCommand: 'curl -X GET "https://api.example.com/data" -H "Authorization: Bearer YOUR_API_TOKEN"',
    pythonCommand: `import requests
  
  url = "https://api.example.com/data"
  headers = {
      "Authorization": "Bearer YOUR_API_TOKEN"
  }
  response = requests.get(url, headers=headers)
  print(response.json())`,
};

export const dashboardCardData = [
    { title: "Total Reviews", value: 33970, percentage: 10 },
    { title: "New Feature", value: 3220, percentage: 81 },
    { title: "Bug Reports", value: 7421, percentage: 12 },
    { title: "Questions", value: 381, percentage: 3 },
    { title: "Appreciations", value: 11806, percentage: 35.1 },
    { title: "Spams", value: 11806, percentage: 35.1 },
];

export const imageDashboardCardData = [
    { title: "CSV", value: "1000", percentage: 5 },
    { title: "App Store", value: "2500", percentage: 15 },
    { title: "Play Store", value: "3200", percentage: 20 },
    { title: "API", value: "800", percentage: -10 },
    { title: "G2", value: "900", percentage: 12 },
];
  
  